/* eslint-disable @typescript-eslint/no-magic-numbers */

import { Injectable, OnDestroy } from '@angular/core';
import { CurrencyService, LanguageService, TranslationService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { UtilLocaleNumberParser } from '@util/classes/util-locale-number-parser.class';
import { getCorrectLangIsoCode } from '@util/functions/lang-isocodes';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KurzUser } from '../kurz-components/shared/types/kurz-user.interface';
import { environment } from 'src/environments/environment';

export type KurzAreaUnitCode = 'M2-M' | 'PFI-I' | 'MSI-I' | 'MMM';
export type KurzLengthUnitCode = 'm' | 'ft';
export type KurzWidthUnitCode = 'mm' | 'in';
export type KurzUnitCode = KurzAreaUnitCode | KurzLengthUnitCode | KurzWidthUnitCode;

export function extractKurzAreaUnitCode(input: string): KurzAreaUnitCode {
  if (typeof input !== 'string') {
    return null;
  }
  input = input.toUpperCase();
  if (input.startsWith('M2')) {
    return 'M2-M';
  } else if (input.startsWith('P')) {
    return 'PFI-I';
  } else if (input.startsWith('MS')) {
    return 'MSI-I';
  } else if (input.startsWith('MMM')) {
    return 'MMM';
  }
  return null;
}

// eslint-disable-next-line
const PFI_TO_MSI_FACTOR = 240;
// eslint-disable-next-line
const PFI_TO_M2_FACTOR = 154.8384;
// eslint-disable-next-line
const MSI_TO_M2_FACTOR = 0.64516;
// eslint-disable-next-line
const MMM_TO_M2_FACTOR = 6.1;


export function getAreaConversionFactor(from: KurzAreaUnitCode, to: KurzAreaUnitCode) {
  if (from === 'M2-M') {
    if (to === 'M2-M') {
      return 1;
    }
    if (to === 'MSI-I') {
      return 1 / MSI_TO_M2_FACTOR;
    }
    if (to === 'PFI-I') {
      return 1 / PFI_TO_M2_FACTOR;
    }
    if (to === 'MMM') {
      return 1 / MMM_TO_M2_FACTOR;
    }
  }

  if (from === 'MSI-I') {
    if (to === 'M2-M') {
      return MSI_TO_M2_FACTOR;
    }
    if (to === 'MSI-I') {
      return 1;
    }
    if (to === 'PFI-I') {
      return 1 / PFI_TO_MSI_FACTOR;
    }
    // if (to === 'MMM') {
    //   return 0;
    // }
  }

  if (from === 'PFI-I') {
    if (to === 'M2-M') {
      return PFI_TO_M2_FACTOR;
    }
    if (to === 'MSI-I') {
      return PFI_TO_MSI_FACTOR;
    }
    if (to === 'PFI-I') {
      return 1;
    }
    // if (to === 'MMM') {
    //   return 0;
    // }
  }

  if (from === 'MMM') {
    if (to === 'M2-M') {
      return MMM_TO_M2_FACTOR;
    }
    // if (to === 'MSI-I') {
    //   return 0;
    // }
    // if (to === 'PFI-I') {
    //   return 0;
    // }
    if (to === 'MMM') {
      return 1;
    }
  }

  console.error('Was not able to receive area conversion factor from ', from, ' to ', to);
  return 0;
}

export enum KurzUnitType {
  Length = 'length',
  Width = 'width',
  Area = 'area',
}

export enum KurzUnitTranscription {
  Meter = '{{value}} m',
  Millimeter = '{{value}} mm',
  SquareMeter = '{{value}} m²',
  Feet = '{{value}} ′',
  Inch = '{{value}} ″',
  SquareFeet = '{{value}} sqft',
  MSI = '{{value}} MSI',
  PFI = '{{value}} PFI',
  MMM = '{{value}} 61m*100mm'
}

export interface KurzUnitFormattedNumberOptions {
  explicitUnitSystem?: KurzUnitCode;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

export interface KurzUnitFormattedPriceOptions {
  /**
   * language codes according to ISO 639-1
   */
  explicitLang?: string;
  /**
   * Currency codes according to ISO 4217
   */
  explicitCurrency?: string;
}

export type KurzUnitSystem = 'imperial' | 'metrics';

@Injectable({providedIn: 'root'})
export class KurzUnitService implements OnDestroy {

  private areaUnitCodeBehaviorSubject = new BehaviorSubject<KurzAreaUnitCode>('M2-M');
  private lengthUnitCodeBehaviorSubject = new BehaviorSubject<KurzLengthUnitCode>('m');
  private widthUnitCodeBehaviorSubject = new BehaviorSubject<KurzWidthUnitCode>('mm');

  private currentUnitTranscriptionMap = new Map<KurzUnitType, KurzUnitTranscription>();
  private _lang: string;
  private _langSubscription: Subscription;
  private _cur: string;
  private _curSubscription: Subscription;
  private _userSubscription: Subscription;
  private _curUnitCode: 'M' | 'I';

  get currentLength(): KurzUnitTranscription {
    return this.currentUnitTranscriptionMap.get(KurzUnitType.Length);
  }

  get currentWidth(): KurzUnitTranscription {
    return this.currentUnitTranscriptionMap.get(KurzUnitType.Width);
  }

  get currentArea(): KurzUnitTranscription {
    return this.currentUnitTranscriptionMap.get(KurzUnitType.Area);
  }

  get currentUnitCode(): 'M' | 'I' {
    return this._curUnitCode;
  }

  get systemOfMeasurement(): 'M' | 'I' {
    return this._curUnitCode;
  }

  get currentLocale(): string {
    return this._lang;
  }

  constructor(
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly translationService: TranslationService,
    private readonly userAccount: UserAccountFacade
  ) {

    // Default
    this.currentUnitTranscriptionMap
    .set(KurzUnitType.Area, KurzUnitTranscription.SquareMeter)
    .set(KurzUnitType.Length, KurzUnitTranscription.Meter)
    .set(KurzUnitType.Width, KurzUnitTranscription.Millimeter);



    this._langSubscription = this.languageService.getActive().subscribe(lang => this._lang = getCorrectLangIsoCode(lang));
    this._curSubscription = this.currencyService.getActive().subscribe(cur => {
      this._cur = cur;
    });

    this._userSubscription = this.userAccount.get().pipe(filter(_ => !!_)).subscribe((user: KurzUser) => {

      if (!user.areaCalculationUnit?.systemOfMeasurement) {
        console.warn('measurement system was not found for user: "M" (metric) was set by default');
      }

      const areaCode = extractKurzAreaUnitCode(user.areaCalculationUnit?.code || 'M2-M');
      const widthCode = user.areaCalculationUnit?.unitOfWidth || 'mm' as KurzWidthUnitCode;
      const lengthCode = user.areaCalculationUnit?.unitOfLength || 'm' as KurzLengthUnitCode;

      this._curUnitCode = user.areaCalculationUnit?.systemOfMeasurement || 'M';

      this.setAreaUnitByCode(areaCode);
      this.setWidthUnitByCode(widthCode);
      this.setLengthUnitByCode(lengthCode);
    });

  }

  ngOnDestroy() {
    this._langSubscription?.unsubscribe();
    this._curSubscription?.unsubscribe();
    this._userSubscription?.unsubscribe();
  }

  setAreaUnitByCode(areaCode: KurzAreaUnitCode) {

    if (areaCode.includes('M2')) {
      this.currentUnitTranscriptionMap.set(KurzUnitType.Area, KurzUnitTranscription.SquareMeter);
      this.areaUnitCodeBehaviorSubject.next('M2-M');
    }

    if (areaCode.includes('MSI')) {
      this.currentUnitTranscriptionMap.set(KurzUnitType.Area, KurzUnitTranscription.MSI);
      this.areaUnitCodeBehaviorSubject.next(areaCode);
    }

    if (areaCode.includes('PFI')) {
      this.currentUnitTranscriptionMap.set(KurzUnitType.Area, KurzUnitTranscription.PFI);
      this.areaUnitCodeBehaviorSubject.next(areaCode);
    }
  }

  getAreaUnitFromCode(areaCode: KurzAreaUnitCode): KurzUnitTranscription {

    if (areaCode.includes('M2')) {
      return KurzUnitTranscription.SquareMeter;
    }

    if (areaCode.includes('MSI')) {
      return KurzUnitTranscription.MSI;
    }

    if (areaCode.includes('PFI')) {
      return KurzUnitTranscription.PFI;
    }
  }

  setWidthUnitByCode(widthCode: KurzWidthUnitCode) {
    if (widthCode === 'mm') {
      this.currentUnitTranscriptionMap.set(KurzUnitType.Width, KurzUnitTranscription.Millimeter);
      this.widthUnitCodeBehaviorSubject.next(widthCode);
    }

    if (widthCode === 'in') {
      this.currentUnitTranscriptionMap.set(KurzUnitType.Width, KurzUnitTranscription.Inch);
      this.widthUnitCodeBehaviorSubject.next(widthCode);
    }
  }

  setLengthUnitByCode(lengthCode: KurzLengthUnitCode) {

    if (lengthCode === 'm') {
      this.currentUnitTranscriptionMap.set(KurzUnitType.Length, KurzUnitTranscription.Meter);
      this.lengthUnitCodeBehaviorSubject.next(lengthCode);
    }

    if (lengthCode === 'ft') {
      this.currentUnitTranscriptionMap.set(KurzUnitType.Length, KurzUnitTranscription.Feet);
      this.lengthUnitCodeBehaviorSubject.next(lengthCode);
    }
  }

  getUnit(type: KurzUnitType, withValuePlaceholder?: boolean): KurzUnitTranscription | string {
    const str = this.currentUnitTranscriptionMap.get(type);
    return withValuePlaceholder ? str : ((str as string).replace('{{value}}', '').trim());
  }

  getAreaUnitCodeStream(): Observable<KurzAreaUnitCode> {
    return this.areaUnitCodeBehaviorSubject.asObservable();
  }

  getWidthUnitCodeStream(): Observable<KurzWidthUnitCode> {
    return this.widthUnitCodeBehaviorSubject.asObservable();
  }

  getLengthUnitCodeStream(): Observable<KurzLengthUnitCode> {
    return this.lengthUnitCodeBehaviorSubject.asObservable();
  }

  private getLengthValuePlaceholder(unitCode?: KurzLengthUnitCode) {
    if (unitCode) {
      return unitCode === 'm' ? KurzUnitTranscription.Meter : KurzUnitTranscription.Feet;
    } else {
      return this.getUnit(KurzUnitType.Length, true);
    }
  }

  getFormattedLength(value: number, options?: KurzUnitFormattedNumberOptions): string {
    const valPlaceholder = this.getLengthValuePlaceholder(options?.explicitUnitSystem as KurzLengthUnitCode);
    const res = valPlaceholder.replace('{{value}}', this.getFormattedNumber(value, options?.maximumFractionDigits ?? 2, options?.minimumFractionDigits ?? 0));
    return res;
  }

  private getWidthValuePlaceholder(unitCode?: KurzWidthUnitCode) {
    if (unitCode) {
      return unitCode === 'mm' ? KurzUnitTranscription.Millimeter : KurzUnitTranscription.Inch;
    } else {
      return this.getUnit(KurzUnitType.Width, true);
    }
  }

  getFormattedWidth(value: number, options?: KurzUnitFormattedNumberOptions): string {
    const valPlaceholder = this.getWidthValuePlaceholder(options?.explicitUnitSystem as KurzWidthUnitCode);
    if (environment.currentBaseSite === 'kurz-us') {
      options ||= {};
      options.maximumFractionDigits = 3;
    }
    const res = valPlaceholder.replace('{{value}}', this.getFormattedNumber(value, (options?.maximumFractionDigits ?? 2), (options?.minimumFractionDigits ?? 0)));
    return res;
  }

  private getAreaValuePlaceholder(unitSystem?: KurzAreaUnitCode) {
    if (unitSystem) {
      unitSystem = extractKurzAreaUnitCode(unitSystem);
      if (unitSystem === 'M2-M') {
        return KurzUnitTranscription.SquareMeter;
      }
      if (unitSystem === 'MSI-I') {
        return KurzUnitTranscription.MSI;
      }
      if (unitSystem === 'PFI-I') {
        return KurzUnitTranscription.PFI;
      }
      if (unitSystem === 'MMM') {
        return KurzUnitTranscription.MMM;
      }
      console.error('no transcription found for unit system', unitSystem);
    } else {
      return this.getUnit(KurzUnitType.Area, true);
    }
  }

  getFormattedArea(value: number, options?: KurzUnitFormattedNumberOptions): string {
    const valPlaceholder = this.getAreaValuePlaceholder(options?.explicitUnitSystem as KurzAreaUnitCode);
    const res = valPlaceholder.replace('{{value}}', this.getFormattedNumber(value, options?.maximumFractionDigits ?? 2, options?.minimumFractionDigits ?? 0));
    return res;
  }

  /**
   * calculates the area taking the user's wanted unit of measurements into account
   */
  calculateArea(width: number, length: number, quantity = 1, optionalAreaCode?: KurzAreaUnitCode): number {

    let area: number;
    const areaUnit = (optionalAreaCode ? this.getAreaUnitFromCode(optionalAreaCode) : null) || this.currentUnitTranscriptionMap.get(KurzUnitType.Area);

    if (areaUnit === KurzUnitTranscription.PFI) {

      // (length*width/12)/(200*100/12)*quantity = area in PSI
      area = (length * width / 12) / (200 * 100 / 12) * quantity;

    } else if (areaUnit === KurzUnitTranscription.MSI) {

      // (length*12*width)/1000*quantity = area in MSI
      area = (length * 12 * width) / 1000 * quantity;

    } else if (areaUnit === KurzUnitTranscription.SquareMeter) {

      // length*width/1000*quantity = area in square meter
      area = length * width / 1000 * quantity;

    } else {

      // length*width/12*quantity = area in Square feet
      area = length * width / 12 * quantity;

    }

    return area;
  }

  getFormattedPrice(value: number, options?: KurzUnitFormattedPriceOptions): string {
    if (typeof value !== 'number') {
      return '-';
    }

    let res: string;

    if (options?.explicitCurrency) {
      const lang = getCorrectLangIsoCode(options?.explicitLang || this._lang);
      const numFormatOptions: (Intl.NumberFormatOptions & {currencyDisplay: 'symbol' | 'code' | 'name';}) = {
        style: 'currency',
        currency: options.explicitCurrency,
        currencyDisplay: 'code'
      };

      res = value.toLocaleString(lang, numFormatOptions);
    } else {
      res = this.getFormattedNumber(value, 2, 2) + ' ?';
    }

    return res;
  }


  /**
   * returns a formatted number according to the current user's currently used language
   * @param value - numeric value
   * @param maximumFractionDigits - maximum Fraction Digits, default value is 2
   * @param minimumFractionDigits - minimum Fraction Digits, default value is same as maximum Fraction Digits
   * Note: set minimumFractionDigits to 0 if you allow integers
   */
  getFormattedNumber(
    value: number,
    maximumFractionDigits = void 0,
    minimumFractionDigits = maximumFractionDigits,
    unit?: KurzUnitTranscription
  ): string {

    if (typeof maximumFractionDigits !== 'number') {
      maximumFractionDigits = 2;
      if (environment.currentBaseSite === 'kurz-us') {
        maximumFractionDigits = 3;
      }
    }

    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits,
      maximumFractionDigits,
    };
    const str = value?.toLocaleString(this._lang, options) || '-';
    return unit
      ? (unit.toString()).replace('{{value}}', str)
      : str;

  }

  parseFormattedNumber(num: string, locale?: string): number {
    locale = getCorrectLangIsoCode(locale || this._lang);
    const parser = new UtilLocaleNumberParser(locale);
    return parser.parse(num);
  }

  getFormattedDate(isoDate: string, locale?: string): string {

    const date = new Date(isoDate);
    const isoCode = getCorrectLangIsoCode(locale || navigator.language || this._lang);
    const resolvedOptions = (new Intl.DateTimeFormat(isoCode)?.resolvedOptions() || {}) as Intl.DateTimeFormatOptions;

    resolvedOptions.day = '2-digit';
    resolvedOptions.month = '2-digit';

    const str = date?.toLocaleDateString(isoCode, resolvedOptions) || '';
    return str;
  }


  /**
   * translates the kurz specific unit strings (M2, ROL, PFI, MSI, MMM, FTI' note: case insensitive in algorithm)
   * @param kurzUnit - string, unit
   * @param unitFactor - number, unit factor of the translation, default: 1
   * @param maximumFractionDigits - number, maximum allowed fraction digits of the number, default: 2
   * @param minimumFractionDigits - number, minimum allowed fraction digits of the number, default: 0
   * @returns Observable<string>
   */
  getKurzUnitTranslation(kurzUnit: string = 'notfound', unitFactor: number = 1, maximumFractionDigits = 2, minimumFractionDigits = 0): Observable<string> {
    kurzUnit = kurzUnit || 'notfound';
    let unit = kurzUnit?.toLowerCase?.() || '';
    unit = (unit === 'rol' && unitFactor !== 1) ? 'rol_plural' : unit;
    unit = (unit === 'pieces' && unitFactor !== 1) ? 'pieces_plural' : unit;
    const value = this.getFormattedNumber(unitFactor, maximumFractionDigits, minimumFractionDigits);
    return this.translationService.translate('kurzUnit.' + unit, {unitFactor: value});
  }


  getPricePerUnit(value: number, kurzUnit: string, unitFactor: number, explicitCurrencyIso?: string, separator = ' / '): Observable<string> {

    const price = of(this.getFormattedPrice(value, {explicitCurrency: explicitCurrencyIso}));

    const unitTranslation = this.getKurzUnitTranslation(kurzUnit, unitFactor);

    return combineLatest([price, unitTranslation]).pipe(map(([price, unit]) => {
      return price + separator + unit;
    }));

  }

}

// -------------------------------------------------------


export class KurzUnitTestingService {

  get currentLength(): KurzUnitTranscription {
    return KurzUnitTranscription.Meter;
  }

  get currentWidth(): KurzUnitTranscription {
    return KurzUnitTranscription.Meter;
  }

  get currentArea(): KurzUnitTranscription {
    return KurzUnitTranscription.Meter;
  }

  get currentUnitCode(): 'M' | 'I' {
    return 'M';
  }

  get systemOfMeasurement(): 'M' | 'I' {
    return 'M';
  }

  getUnit(type: KurzUnitType, withValuePlaceholder?: boolean): KurzUnitTranscription | string {
    return 'Unit';
  }

  getFormattedLength(value: number, options?: KurzUnitFormattedNumberOptions): string {
    return value + 'Length';
  }

  calculateArea(width: number, length: number, quantity = 1): number {
    return 1;
  }

  getFormattedWidth(value: number, options?: KurzUnitFormattedNumberOptions): string {
    return value + 'Width';
  }

  getFormattedArea(value: number, options?: KurzUnitFormattedNumberOptions): string {
    return value + 'Area';
  }

  getFormattedPrice(value: number, options?: KurzUnitFormattedPriceOptions): string {
    return value + 'Price';
  }

  getFormattedNumber(
    value: number,
    maximumFractionDigits = 2,
    minimumFractionDigits = maximumFractionDigits,
    unit?: KurzUnitTranscription
  ): string {
    return '' + value;
  }

  parseFormattedNumber(num: string, locale?: string): number {
    return 1;
  }

  getFormattedDate(isoDate: string, locale?: string): string {
    return isoDate || '';
  }

  getKurzUnitTranslation(kurzUnit: string, unitFactor: number, maximumFractionDigits = 2, minimumFractionDigits = 0): Observable<string> {
    return of(unitFactor + kurzUnit + ':' + maximumFractionDigits + ':' + minimumFractionDigits);
  }

  getAreaUnitCodeStream() {
    return of('M2-M');
  }

  getWidthUnitCodeStream() {
    return of('m');
  }

  getLengthUnitCodeStream() {
    return of('mm');
  }

}
