

/**
 * transform strings to BCP 47 language iso codes - https://www.rfc-editor.org/rfc/rfc5646
 */
export function getCorrectLangIsoCode(str: string): string {

  // maybe this is enough
  // else TODO
  str = str.replaceAll('_', '-');
  return str;
}
